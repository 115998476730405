var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fieldset',[_c('legend',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('rewards.edit_info.address_select_title'))+" ")]),_c('div',{},_vm._l((_vm.addresses),function(item,key){return _c('div',{key:key},[_c('input',{staticClass:"sr-only",attrs:{"id":item.code,"type":"radio","name":"address-choice"},domProps:{"value":item.code,"checked":_vm.value === item.code},on:{"input":function($event){return _vm.selectAddress(item)}}}),_c('label',{staticClass:"flex items-center p-10 mx-6 my-8 rounded cursor-pointer border-grey-300 border-2",class:{
            'border-blue-500': _vm.value === item.code,
            'border-transparent': _vm.value !== item.code,
          },attrs:{"for":item.code}},[_c('Icon',{staticClass:"w-40 text-xl text-center rounded-full",class:{
              'text-blue-500': _vm.value === item.code,
              'text-grey-300': _vm.value !== item.code,
            },attrs:{"iconClass":_vm.value === item.code ? `fas fa-circle` : `far fa-circle`,"color":"bg-blue-500"}}),_c('h5',{staticClass:"pl-12 text-sm font-semibold"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)])}),0)]),(_vm.supportClientNumber)?_c('div',{staticClass:"text-grey-500"},[_vm._v(" "+_vm._s(_vm.$t('rewards.edit_info.address_select_help', [_vm.supportClientNumber]))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }