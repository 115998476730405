<template>
  <div>
    <fieldset>
      <legend class="font-semibold">
        {{ $t('rewards.edit_info.address_select_title') }}
      </legend>
      <div class="">
        <div v-for="(item, key) in addresses" :key="key">
          <input
            :id="item.code"
            class="sr-only"
            type="radio"
            name="address-choice"
            :value="item.code"
            :checked="value === item.code"
            @input="selectAddress(item)"
          />
          <label
            :for="item.code"
            :class="{
              'border-blue-500': value === item.code,
              'border-transparent': value !== item.code,
            }"
            class="flex items-center p-10 mx-6 my-8 rounded cursor-pointer border-grey-300 border-2"
          >
            <Icon
              class="w-40 text-xl text-center rounded-full"
              :class="{
                'text-blue-500': value === item.code,
                'text-grey-300': value !== item.code,
              }"
              :iconClass="
                value === item.code ? `fas fa-circle` : `far fa-circle`
              "
              color="bg-blue-500"
            />
            <h5 class="pl-12 text-sm font-semibold">
              {{ item.title }}
            </h5>
          </label>
        </div>
      </div>
    </fieldset>
    <div v-if="supportClientNumber" class="text-grey-500">
      {{ $t('rewards.edit_info.address_select_help', [supportClientNumber]) }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import supportMixin from '../../mixins/support'
export default {
  mixins: [supportMixin],
  props: {
    autoReward: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 'PRIMARY',
    }
  },
  computed: {
    ...mapGetters('member', ['addresses']),
  },
  methods: {
    ...mapActions('rewards', [
      'updateDeliveryAddress',
      'updateAutoRewardDeliveryAddress',
    ]),
    selectAddress(selectedAddress) {
      this.value = selectedAddress.code

      if (this.autoReward) {
        this.updateAutoRewardDeliveryAddress(selectedAddress)
      } else {
        this.updateDeliveryAddress(selectedAddress)
      }
    },
  },
}
</script>
