<template>
  <div class="w-full max-w-3xl py-20 bg-white mb-100">
    <h6 class="cursor-pointer mt-5 mb-20" @click="$router.back()">
      <Icon iconClass="fas fa-chevron-left" />
      {{ $t('rewards.automatic_rewards.confirmation.edit_selection') }}
    </h6>
    <div class="border border-grey-200 rounded-lg mt-10">
      <h1 class="mx-30 my-20 h2">
        {{ $t('rewards.automatic_rewards.confirmation.title') }}
      </h1>
      <div class="px-30 pb-35 mb-30 border-b border-grey-200">
        <div class="flex">
          <img
            aria-hidden="true"
            :src="reward.imageUrl"
            :alt="reward.name"
            class="mr-20 self-center"
            height="43px"
            width="72px"
          />
          <div class="flex flex-col self-center">
            <h2 class="h5">
              {{
                $t('rewards.automatic_rewards.confirmation.gift_card', [
                  reward.name,
                ])
              }}
            </h2>
            <p class="text-sm">
              {{ $t(`rewards.automatic_rewards.confirmation.${reward.type}`) }}
            </p>
            <a
              class="text-blue-500 underline font-semibold text-sm"
              @click="showDescription = !showDescription"
            >
              {{
                $t(
                  `rewards.item_basket.${
                    showDescription ? 'hide' : 'view'
                  }_description`
                )
              }}
            </a>
          </div>
        </div>
        <div
          v-if="showDescription"
          class="mt-10 description"
          v-html="reward.description"
        />
      </div>
      <div class="flex flex-col mx-30 pb-30">
        <div v-if="reward.type === 'digital'">
          <p class="text-sm text-grey-500">
            {{ $t('rewards.automatic_rewards.confirmation.email') }}
          </p>
          <p>{{ memberInfo.email }}</p>
        </div>
        <div v-else-if="reward.type === 'physical'">
          <RewardAddressSelector :autoReward="true" />
        </div>
      </div>
    </div>

    <HMButton class=" mt-20 w-full" :busy="loading" @click="save">
      {{ $t('rewards.automatic_rewards.confirmation.confirm') }}
    </HMButton>

    <HMButton
      class="w-full mt-20 self-center border border-grey-300"
      color="white"
      @click="$router.push('/rewards')"
    >
      {{ $t('common.cancel_btn') }}
    </HMButton>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import RewardAddressSelector from './RewardAddressSelector.vue'

export default {
  components: { RewardAddressSelector },
  data() {
    return {
      editInfoModal: false,
      showDescription: false,
    }
  },
  computed: {
    ...mapGetters('rewards', {
      reward: 'selectedAutoReward',
      address: 'autoRewardDeliveryAddress',
      loading: 'setupAutoRewardLoading',
    }),
    ...mapGetters('member', ['memberInfo']),
  },
  created() {
    if (!this.reward.id) {
      this.$router.push('/rewards')
    }
  },
  methods: {
    ...mapActions('rewards', ['setupAutoReward', 'openConfirmAutoReward']),
    save() {
      this.setupAutoReward({
        productId: this.reward.productId,
        delivery: this.reward.type,
        address: this.address,
      }).then(() => {
        this.openConfirmAutoReward()
        this.$router.push('/rewards')
      })
    },
  },
}
</script>

<style>
.description > ul {
  list-style: disc;
  padding-left: 40px;
}
</style>
